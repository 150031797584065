<template>
  <div>
    <!-- Init -->
  </div>
</template>

<script>
  export default {
    name: 'LocalStorageReset',

    created () {
      localStorage.clear()
      this.$toast('Im Browser gespeicherte Daten wurden erfolgreich entfernt')
      this.$router.push('/app', () => {})
    },
  }
</script>
